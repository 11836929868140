<template>
  <v-dialog
    v-model="show"
    persistent
    :width="width"
  >
    <v-card class="pa-4">
      <v-card-title class="headline justify-center py-2">
        {{ header }}
      </v-card-title>
      <v-card-text class="body text-center py-2">
        <slot name="body">
          Apakah anda yakin ingin menghapus data ini?
        </slot>
      </v-card-text>
      <v-card-actions
        class="footer justify-center py-4"
        @click.stop="show=false"
      >
        <slot
          name="footer"
        ></slot>
        <v-btn
          color="primary"
          large
          :loading="isLoadingButton"
          :disabled="isDisabledButton"
          @click="$emit('destroy-button')"
        >
          Hapus
        </v-btn>

        <v-btn
          color="primary"
          large
          outlined
          @click="$emit('close-button')"
        >
          Batal
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  props: {
    visible: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => '',
    },
    width: {
      type: Number,
      default: () => 500,
    },
    isLoadingButton: {
      type: Boolean,
      default: () => false,
    },
    isDisabledButton: {
      type: Boolean,
      default: () => false,
    },
    header: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      },
    },
  },
}
</script>

<style>
</style>
