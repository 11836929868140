<template>
  <v-card>
    <slot name="body"></slot>
  </v-card>
</template>

<script>
export default {
  name: 'MainCard',
}
</script>

<style>
</style>
