<template>
  <div>
    <v-skeleton-loader
      v-bind="attrs"
      type="table-thead,table-tbody,table-tfoot"
    >
    </v-skeleton-loader>
  </div>
</template>

<script>
export default {
  name: 'SkeletonLoaderTable',
  data() {
    return {
      attrs: {
        boilerplate: false,
        elevation: 5,
      },
    }
  },
}
</script>

<style>
</style>
